import React from "react"
import { formatDistance } from "date-fns"

const getDisplayName = author => {
  let displayName = author && author.length ? author : "Anonymous"
  const [firstName, lastName] = displayName.split(" ")
  if (lastName) {
    displayName = `${firstName} ${lastName[0]}.`
  } else {
    displayName = firstName
  }
  return displayName
}

export const Testimonial = ({ text, author, course }) => (
  <div className="testimonial w-full border-b">
    <div className="relative mx-auto px-2 sm:px-8 py-16 sm:py-32 max-w-screen-xl">
      <div className="relative text-center">
        <figure className="font-display">
          <blockquote className="relative text-2xl sm:text-3xl mx-auto italic mb-4 px-12 sm:px-16">
            <div className="absolute top-o left-0 -mt-5 pl-2">
              <svg
                className="h-8 w-8 sm:h-12 sm:w-12"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.216 9H35c-2.591 4.338-4.385 8.474-5.282 12.307h4.784L32.907 32H22.542l.697-4.842C24.435 19.289 27.226 13.237 30.216 9zM12.674 9h4.884c-2.69 4.338-4.485 8.474-5.382 12.307h4.784L15.365 32H5l.698-4.842C6.894 19.289 9.684 13.237 12.674 9z"
                  fill="#4E68D0"
                  fillRule="evenodd"
                />
              </svg>
            </div>
            {text}
            <div className="absolute bottom-0 right-0 -mb-5 pr-2">
              <svg
                className="h-8 w-8 sm:h-12 sm:w-12"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.784 32H5c2.591-4.338 4.385-8.474 5.282-12.307H5.498L7.093 9h10.365l-.697 4.842C15.565 21.711 12.774 27.763 9.784 32zm17.542 0h-4.884c2.69-4.338 4.485-8.474 5.382-12.307H23.04L24.635 9H35l-.698 4.842C33.106 21.711 30.316 27.763 27.326 32z"
                  fill="#4E68D0"
                  fillRule="evenodd"
                />
              </svg>
            </div>
          </blockquote>
          <figcaption className="tracking-wide font-bold text-lg">
            <p className="text-indigo-700 uppercase">
              {getDisplayName(author)}
            </p>
            {course && <p className="text-gray-600 text-sm">{course}</p>}
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
)

export const TestimonialSmall = ({
  text,
  author,
  course,
  rating = 5,
  date,
}) => {
  const stars = []
  for (let i = 0; i < rating; i += 1) {
    stars.push("★")
  }

  let displayDate
  if (date) {
    const [year, month, day] = date.split("-")
    displayDate = new Date(year, month - 1, day)
  }

  return (
    <div className="">
      <div className="relative mx-auto px-4 pb-10 mb-5">
        <div className="relative">
          <figure className="font-display">
            <blockquote className="relative text-2xl mx-auto italic mb-4 text-gray-700">
              {text}
            </blockquote>
            <div className="absolute top-0 -left-4 -mt-5 opacity-20">
              <svg
                className="h-16 w-16"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.216 9H35c-2.591 4.338-4.385 8.474-5.282 12.307h4.784L32.907 32H22.542l.697-4.842C24.435 19.289 27.226 13.237 30.216 9zM12.674 9h4.884c-2.69 4.338-4.485 8.474-5.382 12.307h4.784L15.365 32H5l.698-4.842C6.894 19.289 9.684 13.237 12.674 9z"
                  fill="#7ca1b4"
                  fillRule="evenodd"
                />
              </svg>
            </div>
            <figcaption className="tracking-wide font-bold text-lg flex flex-row justify-between items-center">
              <div>
                <p className="text-blue-gray-700 uppercase">
                  {getDisplayName(author)}
                </p>
                {course && <p className="text-gray-600 text-sm">{course}</p>}
              </div>
              <div className="flex flex-col items-end">
                <p className="text-yellow-300">{stars.join("")}</p>
                <p className="text-gray-600 text-sm font-normal">
                  {formatDistance(displayDate, new Date(), { addSuffix: true })}
                </p>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  )
}
